// 
// card.scss
//

// .card {
//     box-shadow: $box-shadow-sm;
//     margin-bottom: $grid-gutter-width;
// }
.card.background-transparent {
    // background: transparent;
    background: #ECF1FF;
    box-shadow: none;
    margin-bottom: 0;
    padding: 20px !important;
}

.card-drop {
    font-size: 20px;
    line-height: 0;
    color: inherit;
}

.card-widgets {
    float: right;
    height: 16px;

    >a {
        color: inherit;
        font-size: 18px;
        display: inline-block;
        line-height: 1;

        &.collapsed {
            i {
                &:before {
                    content: "\F0415";
                }
            }
        }
    }
}


// Card title / Card Header
.card-title,
.card-header {
    margin-top: 0;
}


//Card disable loading (Custom Cards)
.card-disabled {
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    border-radius: $card-border-radius;
    background: $card-overlay-bg;
    cursor: progress;
    z-index: 1;

    .card-portlets-loader {
        background-color: $card-overlay-color;
        animation: rotatebox 1.2s infinite ease-in-out;
        height: 30px;
        width: 30px;
        position: absolute;
        left: 50%;
        top: 50%;
        border-radius: 3px;
        margin-left: - ($grid-gutter-width / 2);
        margin-top: - ($grid-gutter-width / 2);
    }
}

@keyframes rotatebox {
    0% {
        transform: perspective(120px) rotateX(0deg) rotateY(0deg);
    }

    50% {
        transform: perspective(120px) rotateX(-180.1deg) rotateY(0deg);
    }

    100% {
        transform: perspective(120px) rotateX(-180deg) rotateY(-179.9deg);
    }
}

// .header-title {
//     font-size: 1rem;
//     margin: 0 0 7px 0;
// }
.header-title {
    font-family: 'Roboto', sans-serif !important;
    font-size: 1.2rem;
    margin: 0 0 7px 0;
    font-weight: 550;

    img {
        width: 2rem;
        padding-right: 5px;
    }
}

.header-title2 {
    font-family: 'Roboto', sans-serif !important;
    font-size: 1.15rem;
    margin: 0 0 7px 0;
    font-weight: 500;

    img {
        width: 2rem;
        padding-right: 5px;
    }
}

.sub-header {
    font-size: 0.875rem;
    margin-bottom: $grid-gutter-width;
    color: $text-muted;
}