// 
// Custom-radio.scss
//


// checkbox & radio color
@each $color,
$value in $theme-colors {
    .form-check {
        &.form-check-#{$color} {
            .form-check-input {
                border: 2px solid #763EEC !important;
            }

            .form-check-input:checked {
                // background-color: $value !important;
                // border-color: $value !important;
                background-color: #763EEC !important;
                border: 1px solid #763EEC !important;
                border-color: #763EEC !important;
            }
        }
    }
}